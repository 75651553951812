import { blue, deepOrange, green, grey, orange, yellow } from '@mui/material/colors';
import { PaletteColor } from '@mui/material';

import { AppIssueBrief } from '@playq/services-beetle';

import { UpdateRequestType } from '../components/updateLabels/types';
import { unresolveIssue } from '../constants';
import { IResolvedColor } from '../types';

export const setColorByStatus = (stat: UpdateRequestType | typeof unresolveIssue | undefined): PaletteColor => {
  switch (stat) {
    case UpdateRequestType.Accepted:
      return { contrastText: 'rgba(0, 0, 0, 0.87)', dark: yellow[600], light: yellow[200], main: yellow[700] };
    case UpdateRequestType.Declined:
      return { light: deepOrange[200], main: deepOrange[500], dark: deepOrange[600], contrastText: '#fff' };
    case UpdateRequestType.Resolved:
      return { light: green[200], main: green[400], dark: green[600], contrastText: '#fff' };
    case UpdateRequestType.BeingProcessed:
      return { light: blue[200], main: blue[400], dark: blue[600], contrastText: '#fff' };
    case unresolveIssue:
      return { light: orange[200], main: orange[500], dark: orange[600], contrastText: 'rgba(0, 0, 0, 0.87)' };
    default:
      return {
        light: grey[200],
        main: 'rgba(189, 189, 189, 0.6)',
        dark: grey[600],
        contrastText: 'rgba(0, 0, 0, 0.6)',
      };
  }
};

export const chooseResolveColor = (ariaLabel?: string): string => {
  return ariaLabel === IResolvedColor.RESOLVE || ariaLabel === IResolvedColor.RESOLVED ? green[500] : orange[500];
};

export const renderText = (issue: AppIssueBrief, onHoverIssue: boolean) => {
  return onHoverIssue ? hoverText(issue) : defaultText(issue);
};
export const hoverText = (issue: AppIssueBrief) => {
  //hover text
  if (issue.resolved) {
    return 'Unresolve';
  } else {
    return 'Resolve';
  }
};
export const defaultText = (issue: AppIssueBrief) => {
  if (issue.resolved) {
    return IResolvedColor.RESOLVED;
  } else {
    return IResolvedColor.UNRESOLVED;
  }
};
