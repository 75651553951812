import { Environment } from '@playq/octopus-common';

import { history } from '/Router/history';
import { SyntheticEventWithMetaKey } from '/common/models';
import { navigateToURL } from '/helpers';

import { BeetleContentStateType, SupportContentType, ClickEventType } from '../types';

export const getBeetlesRootURL = (appRouteName: string | undefined) => {
  if (!appRouteName) {
    return;
  }
  return `/apps/${appRouteName}/support/beetle`;
};

export const goToBeetles = (appRouteName: string | undefined, env: Environment) => () => {
  const url = getBeetlesRootURL(appRouteName);
  if (!url) {
    return;
  }
  history.push(url, { env });
};
export const getBeetlesTableURL = (type: SupportContentType, appName: string, env: Environment) => {
  return `/apps/${appName}/support/beetle/${type}/${env}`;
};

export const goToBeetlesTable =
  (type: SupportContentType, pathname: string, env: Environment) => (event: ClickEventType) => {
    if (event.metaKey) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    event.preventDefault();
    if (!pathname) {
      return;
    }
    const url = getBeetlesTableURL(type, pathname, env);
    if (url) {
      history.push(url, { env });
    }
  };

export const goToBeetlesTableFromContent = (type: SupportContentType, appRouteName: string, env: Environment) => {
  const url = getBeetlesTableURL(type, appRouteName, env);
  if (url) {
    history.push(url, { env });
  }
};

export const getBeetleURL = (
  type: SupportContentType,
  appRouteName: string | undefined,
  state: BeetleContentStateType
) => {
  if (!appRouteName) {
    return;
  }
  const { env, app, id } = state;
  return `/apps/${appRouteName}/support/beetle/${type}/${env}/${app}/${id}`;
};

export const getBeetleFullURL = (
  type: SupportContentType,
  appRouteName: string | undefined,
  state: BeetleContentStateType
) => {
  const pathToBeetlesList = getBeetlesRootURL(appRouteName);
  if (!pathToBeetlesList) {
    return;
  }
  const fullUrl = window.location.href;
  const beetleURL = getBeetleURL(type, appRouteName, state);
  if (!beetleURL) {
    return;
  }
  const rootURL = fullUrl.split(pathToBeetlesList)[0];
  return `${rootURL}${beetleURL}`;
};

type GoToBeetleContentParams = {
  type: SupportContentType;
  appRouteName: string | undefined;
  beetleState: BeetleContentStateType;
  event?: SyntheticEventWithMetaKey;
};

export const goToBeetleContent = ({ type, appRouteName, beetleState, event }: GoToBeetleContentParams) => {
  const url = getBeetleURL(type, appRouteName, beetleState);
  navigateToURL({ url, state: beetleState, event });
};

export const getDataFromURL = (url: string) => {
  if (!url.includes('/beetle/')) {
    return null;
  }

  const parts = url.split('/beetle/')[1];

  const type = parts.split('/');

  if (type.length > 0) {
    return type[0];
  } else {
    return null;
  }
};

export const getBeetlesURL = (appRouteName: string | undefined) =>
  appRouteName ? `/apps/${appRouteName}/support/beetle` : undefined;

export const blankClick = (e: ClickEventType) => {
  if (!e.metaKey) {
    e.preventDefault();
  }
};

export const handleBreadcrumbClickId = (
  type: SupportContentType,
  { appName, env, app, id }: BeetleContentStateType
) => {
  return (event: ClickEventType) => {
    if (!event.metaKey) {
      return;
    }
    if (!appName) {
      return;
    }
    return window.open(`/apps/${appName}/support/beetle/${type}/${env}/${app}/${id}`, '_blank');
  };
};
export const handleClick = (type: SupportContentType, appName: string, env: Environment) => {
  return (event: ClickEventType) => {
    if (event.metaKey) {
      return window.open(`/apps/${appName}/support/beetle/${type}/${env}`, '_blank');
    }
    if (!appName) {
      return;
    }
    return history.push(getBeetlesTableURL(type, appName, env));
  };
};

export const blankClickBreadcrumbs =
  (appName: string, type: SupportContentType, env: Environment) => (e: SyntheticEventWithMetaKey) => {
    if (!e.metaKey) {
      e.preventDefault();
    } else {
      return window.open(`/apps/${appName}/support/beetle/${type}/${env}`, '_blank');
    }
  };
