/* eslint-disable @typescript-eslint/ban-types,@typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from 'react';
import { boolean, lazy, mixed, object, Schema, string, ValidationError } from 'yup';

import {
  AmazonPaymentServiceConfig,
  ApplePaymentServiceConfig,
  BookkeeperConfig,
  BookkeeperConfigSerialized,
  GooglePaymentServiceConfig,
  Store,
  StoreCurrency,
  StoreHelpers,
} from '@playq/services-bookkeeper';
import {
  OneSignalConfiguration,
  OneSignalConfigurationSerialized,
  SonarConfiguration,
  SonarConfigurationSerialized,
} from '@playq/services-sonar';
import { AppID, Environment } from '@playq/octopus-common';
import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { Either } from '@playq/irt';
import {
  Application,
  ApplicationSerialized,
  FacebookCredentials,
  AppleCredentials,
  GoogleCredentials,
} from '@playq/services-fingerprint';

import { Services2 } from '/api';
import { resetModel } from '/helpers';
import { zeroGuid } from '/constants';

export enum ServicesOptions {
  Fingerprint = 'Fingerprint',
  Bookkeeper = 'Bookkeeper',
  Sonar = 'Sonar',
}
export enum SocialMediaConfiguration {
  FACEBOOK = 'Facebook',
  APPLE = 'Apple',
  GOOGLE = 'Google',
}
export type ValidateArgs = {
  schema?: Schema<unknown>;
  value: unknown;
  field?: string;
  setErrors?: Dispatch<SetStateAction<Record<string, string | undefined> | undefined>>;
};

export const fieldRequiredSchema = (field: string) => string().trim().required(`${field} is required!`);

const verificationConfigSchema = object().shape({
  verificationUri: fieldRequiredSchema('Verification Uri'),
});

export const amazonConfigSchema = verificationConfigSchema.concat(
  object().shape({
    developerSecret: fieldRequiredSchema('Developer Secret'),
  })
);

export const appleConfigSchema = verificationConfigSchema;

export const googleConfigSchema = verificationConfigSchema.concat(
  object().shape({
    clientId: fieldRequiredSchema('Client Id'),
    clientSecret: fieldRequiredSchema('Client Secret'),
    tokenRefreshUri: fieldRequiredSchema('Token Refresh Uri'),
    refreshToken: fieldRequiredSchema('Refresh Token'),
  })
);

export const verificationConfigsSchemaMap = {
  [Store.AmazonMarketplace]: object().shape({
    [AmazonPaymentServiceConfig.FullClassName]: amazonConfigSchema,
  }),
  [Store.AppStore]: object().shape({
    [ApplePaymentServiceConfig.FullClassName]: appleConfigSchema,
  }),
  [Store.GooglePlay]: object().shape({
    [GooglePaymentServiceConfig.FullClassName]: googleConfigSchema,
  }),
};

const verificationSchema: Schema<object> = object().shape(
  StoreHelpers.all.reduce((map: { [store: string]: Schema<any> }, store: Store) => {
    map[store] = lazy((value: string) => {
      return value !== undefined ? verificationConfigsSchemaMap[store] : mixed().notRequired();
    });
    return map;
  }, {})
);

const bookkeeperConfigSchema: Schema<object> = object().shape({
  ignorePaymentFailures: boolean().required(),
  ignorePaymentValidation: boolean().required(),
  allowPaymentsMigration: boolean().required(),
  anchorCurrency: fieldRequiredSchema('Anchor Currency'),
  verificationConfigs: verificationSchema,
});

export const fgpConfigSchema: Schema<object> = object().shape({
  integrations: object().shape({
    facebook: object().shape({
      token: fieldRequiredSchema('App Secret'),
    }),
  }),
});

export const oneSignalSchema: Schema<any> = object().shape({
  restApiToken: fieldRequiredSchema('RestApi Token'),
  appId: fieldRequiredSchema('AppId'),
});

const sonarConfigSchema: Schema<any> = object().shape({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  oneSignal: lazy((value?: OneSignalConfigurationSerialized) => (value ? oneSignalSchema : mixed().notRequired())),
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function createBlankFingerprintConfiguration(_env: Environment): Application {
  const cfg = new Application();
  resetModel(cfg);
  cfg.integrations.facebook = new FacebookCredentials();
  cfg.integrations.apple = new AppleCredentials();
  cfg.integrations.google = new GoogleCredentials();
  cfg.integrations.apple.clientId = '';
  cfg.integrations.google.clientId = '';
  cfg.integrations.facebook.token = '';
  return cfg;
}

export function createBlankBookkeeperConfiguration(env: Environment): BookkeeperConfig {
  const cfg = new BookkeeperConfig();
  cfg.ignorePaymentFailures = true;
  cfg.ignorePaymentValidation = false;
  cfg.allowPaymentsMigration = false;
  cfg.anchorCurrency = StoreCurrency.USD;

  const amazonCfg = new AmazonPaymentServiceConfig();
  const googleCfg = new GooglePaymentServiceConfig();
  const appleCfg = new ApplePaymentServiceConfig();

  if (env === Environment.Sandbox) {
    amazonCfg.verificationUri =
      'https://appstore-sdk.amazon.com/version/1.0/verifyReceiptId/developer/<developerSecret>/user/<userId>/receiptId/<receiptId>';
    googleCfg.verificationUri =
      'https://www.googleapis.com/androidpublisher/v3/applications/<packageName>/purchases/products/<productId>/tokens/<token>';
    googleCfg.tokenRefreshUri = 'https://accounts.google.com/o/oauth2/token';
    appleCfg.verificationUri = 'https://sandbox.itunes.apple.com/verifyReceipt';
  } else {
    amazonCfg.verificationUri =
      'https://appstore-sdk.amazon.com/version/1.0/verifyReceiptId/developer/<developerSecret>/user/<userId>/receiptId/<receiptId>';
    googleCfg.verificationUri =
      'https://www.googleapis.com/androidpublisher/v3/applications/<packageName>/purchases/products/<productId>/tokens/<token>';
    googleCfg.tokenRefreshUri = 'https://accounts.google.com/o/oauth2/token';
    appleCfg.verificationUri = 'https://buy.itunes.apple.com/verifyReceipt';
  }

  cfg.verificationConfigs = {
    [Store.AppStore]: appleCfg,
    [Store.GooglePlay]: googleCfg,
    [Store.AmazonMarketplace]: amazonCfg,
  };

  return cfg;
}

export function createBlankOneSignalConfiguration(
  serialized = true
): OneSignalConfigurationSerialized | OneSignalConfiguration {
  const oneSignal = new OneSignalConfiguration();
  oneSignal.appId = zeroGuid;
  oneSignal.restApiToken = 'RESTApiTokenFromOneSignalWebsite';

  return serialized ? oneSignal.serialize() : oneSignal;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function createBlankSonarConfiguration(_env: Environment): SonarConfiguration {
  const cfg = new SonarConfiguration();
  cfg.oneSignal = createBlankOneSignalConfiguration(false) as OneSignalConfiguration;

  return cfg;
}

export type ServiceConfig = BookkeeperConfig | SonarConfiguration | Application;
export type ServiceConfigSerialized = BookkeeperConfigSerialized | SonarConfigurationSerialized | ApplicationSerialized;

interface IServiceMethods<C, CS> {
  getClassName(): ServicesOptions;
  createBlankConfig: (env: Environment, serialized?: boolean) => C;
  serializeConfig: (cfg: C) => CS;
  deserializeConfig: (cfg: CS) => C;
  validationSchema: Schema<object>;
  getConfig$: (app: AppID, env: Environment, noCache?: boolean) => Promise<Either<GenericFailure, C>>;
  setConfig$: (app: AppID, env: Environment, cfg: C) => Promise<Either<GenericFailure, CommonSuccess>>;
}

type ServiceMethods =
  | IServiceMethods<Application, ApplicationSerialized>
  | IServiceMethods<BookkeeperConfig, BookkeeperConfigSerialized>
  | IServiceMethods<SonarConfiguration, SonarConfigurationSerialized>;

export function getServiceMethods(apiServices: Services2, selectedService: ServicesOptions): ServiceMethods {
  switch (selectedService) {
    case ServicesOptions.Fingerprint: {
      return {
        getClassName: () => ServicesOptions.Fingerprint,
        createBlankConfig: createBlankFingerprintConfiguration,
        serializeConfig: (cfg: Application) => cfg.serialize(),
        deserializeConfig: (cfg: ApplicationSerialized) => new Application(cfg),
        validationSchema: fgpConfigSchema,
        getConfig$: (app: AppID, env: Environment) =>
          apiServices.fingerprintService.get.bind(apiServices.fingerprintService)(app, env, true),
        setConfig$: (app: AppID, env: Environment, cfg: Application) =>
          apiServices.fingerprintService.upsert.bind(apiServices.fingerprintService)(app, env, cfg.integrations),
      };
    }
    case ServicesOptions.Bookkeeper: {
      return {
        getClassName: () => ServicesOptions.Bookkeeper,
        createBlankConfig: createBlankBookkeeperConfiguration,
        serializeConfig: (cfg: BookkeeperConfig) => cfg.serialize(),
        deserializeConfig: (cfg: BookkeeperConfigSerialized) => new BookkeeperConfig(cfg),
        validationSchema: bookkeeperConfigSchema,
        getConfig$: apiServices.configurationService.getBookkeeper.bind(apiServices.configurationService),
        setConfig$: apiServices.configurationService.setupBookkeeper.bind(apiServices.configurationService),
      };
    }
    case ServicesOptions.Sonar: {
      return {
        getClassName: () => ServicesOptions.Sonar,
        createBlankConfig: createBlankSonarConfiguration,
        serializeConfig: (cfg: SonarConfiguration) => cfg.serialize(),
        deserializeConfig: (cfg: SonarConfigurationSerialized) => new SonarConfiguration(cfg),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        validationSchema: sonarConfigSchema,
        getConfig$: apiServices.configurationService.getSonar.bind(apiServices.configurationService),
        setConfig$: apiServices.configurationService.setupSonar.bind(apiServices.configurationService),
      };
    }
  }
}

export function getServiceTitle(type: string): string {
  switch (type) {
    case Application.ClassName:
      return 'Fingerprint Configuration';
    case BookkeeperConfig.ClassName:
      return 'Bookkeeper Configuration';
    case SonarConfiguration.ClassName:
      return 'Sonar Configuration';
    default:
      return 'Unknown Service';
  }
}

export const validate = ({ schema, value, field, setErrors }: ValidateArgs): boolean => {
  if (value === undefined && !field) {
    setErrors?.(undefined);
    return false;
  }
  try {
    schema?.validateSync(value, { abortEarly: false });
    setErrors?.((prevErrors) => {
      if (field) {
        return { ...prevErrors, [field]: undefined };
      }
      return undefined;
    });
    return false;
  } catch (e) {
    if (!(e instanceof ValidationError)) {
      return false;
    }
    if (field) {
      setErrors?.((prevErrors) => ({ ...prevErrors, [field]: (e as ValidationError).message }));
      return true;
    }
    const errors: Record<string, string> = {};
    for (const err of e.inner) {
      const lastDotIdx = err.path.lastIndexOf('.');
      errors[err.path.slice(lastDotIdx + 1)] = err.message;
    }
    setErrors?.(errors);
    return true;
  }
};
