export enum SpaceLessModules {
  Inventory = 'inventory',
  Packages = 'packages',
  Leaderboards = 'leaderboards',
  Matches = 'matches',
  Teams = 'teams',
  Synapse = 'synapse',
  Coupons = 'coupons',
}

export const SpaceLessModulesList = [...new Set(Object.values(SpaceLessModules))];
