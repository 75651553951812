import { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

export interface IUseUniqueCollection<E = string> {
  size: number;

  add: (val: E) => void;
  addMany: (vals: E[]) => void;
  delete: (val: E) => void;
  deleteMany: (vals: E[]) => void;
  has: (val: E) => boolean;
  entries: () => E[];
  clear: () => void;
}

export const useUniqueCollection = <E = string>(init?: Set<E>): IUseUniqueCollection<E> => {
  const [state, setState] = useState<Set<E>>(() => init || new Set<E>());

  useEffect(() => init && setState(init), [init]);

  const size = useMemo(() => state.size, [state]);

  const clone = useCallback((s: Set<E>) => new Set(s), []);

  const add = useCallback((val: E) => setState((s) => clone(s).add(val)), [clone]);

  const addMany = useCallback((vals: E[]) => setState((s) => new Set([...clone(s), ...vals])), [clone]);

  const del = useCallback((val: E) => setState((s) => new Set(_.xor([...clone(s)], [val]))), [clone]);

  const delMany = useCallback((vs: E[]) => setState((s) => new Set(_.xor([...clone(s)], vs))), [clone]);

  const clear = useCallback(() => setState(new Set()), []);

  const has = useCallback((val: E) => state.has(val), [state]);

  const entries = useCallback(() => [...state], [state]);

  return {
    size,
    entries,
    add,
    addMany,
    delete: del,
    deleteMany: delMany,
    clear,
    has,
  };
};
