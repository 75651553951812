import { useEffect, useRef } from 'react';

import { Optional } from '/common/models';

export const useInterval = (callback: VoidFunction, delay: number) => {
  const callBackRef = useRef<Optional<VoidFunction>>();

  useEffect(() => {
    callBackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      callBackRef.current?.();
    };

    if (delay) {
      const itervalID = setInterval(tick, delay);
      return () => {
        clearInterval(itervalID);
      };
    }
  }, [delay]);
};
