import { MutableRefObject, useEffect, useRef } from 'react';

export const useCancelOnUnmount = (dependency: unknown = null): MutableRefObject<boolean> => {
  const didCancelRef = useRef<boolean>(false);

  useEffect(
    () => () => {
      didCancelRef.current = true;
    },
    [dependency]
  );

  return didCancelRef;
};
