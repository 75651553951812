import { useEffect, useState } from 'react';
import { Observable } from 'rxjs';

/**
 * Accepts an observable, and initial value. Handles the observable (-un)subscription logic and returns an actual value.
 * @template T the type of an observable value.
 */
export function useObservableAsValue<T>(observable: Observable<T>, initialValue?: T) {
  const [value, setValue] = useState<T | undefined>(initialValue);

  useEffect(() => {
    const subscription = observable.subscribe((val) => {
      setValue(val);
    });

    return () => subscription.unsubscribe();
  }, [observable]);

  return value;
}
