import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { useMemo } from 'react';

import { PermModule } from '@playq/octopus2-auth';
import { Environment } from '@playq/octopus-common';

import { appToolkit } from '/store';

import { IBeetleParams, SupportContentType } from '../types';

import { getDataFromURL } from './navigationHelpers';

export const useBettleLookupCommonInfo = <I>() => {
  const { canWrite, canWriteLive } = useSelector(appToolkit.selectors.moduleAccess(PermModule.AudienceSupport));

  const params = useParams<IBeetleParams & I>();
  const location = useLocation();
  const readonly = params.env === Environment.Sandbox ? !canWrite : !canWriteLive;

  const currentType = getDataFromURL(location.pathname) as SupportContentType;

  return useMemo(() => {
    return { readonly, params, currentType };
  }, [params, readonly, currentType]);
};
