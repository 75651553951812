import { useCallback, useEffect, useRef, useState } from 'react';

import { IGenericTableEntity } from '/common/models';

import { getInitialQuery } from '../../shared/Table/helpers/getInitialQuery';
import { ITableQuery } from '../../shared/Table/interfaces/ITableQuery';
import { ITableColumnSort } from '../../shared/Table/interfaces/ITableColumnSort';
import { ITableColumnsFilter } from '../../shared/Table/interfaces/ITableColumnsFilter';

import {
  TableQueryFilterParameter,
  TableQueryFilterParameterSerialized,
  IUseTableQueryChangeParams,
  ITableQueryConfig,
} from './types';
import TableQueryWorker from './tableQuery.worker';

// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
const tableQueryWorker: Worker = new TableQueryWorker();
const TIME_TO_WAIT_FOR_QUERY_WITHOUT_LOADER = 100;

/**
 * A hook that helps to perform sorting and filtering queries
 * on tables where this logic is supposed to be handled on UI.
 * @param tableData - full (static) table data that can be changed only by a server request
 * @param tableEntriesSettingHandler - a handler responsible for setting (dynamic) entries currently presented in a table
 * @param tableQuery (optional) - initial query config to store for own purposes (is returned afterall)
 * @param isTableProcessingSettingHandler - a handler responsible for setting a current table "processing" status
 * @returns a tuple that consists of the latest query and a function for performing the last ones.
 */
export function useTableQueryChange({
  tableData,
  tableEntriesSettingHandler,
  tableQuery = getInitialQuery(),
  isTableProcessingSettingHandler,
}: IUseTableQueryChangeParams): [ITableQuery<string>, (qr: ITableQuery<string>) => void] {
  const [query, setQuery] = useState<ITableQuery<string>>(tableQuery);
  const tableDataRef = useRef<IGenericTableEntity[]>(tableData);

  /**
   * A function that performs query (sorting and filtering) logic.
   * It takes benefits of Web Workers and handles all the operations in a separate context.
   * If it is more than 100ms needed to update the current table data, its "processing" status
   * is toggled appropriately and will be falsified right after the query is finished.
   * @param qr - a table query config to be applied to a table
   */
  const changeQuery = useCallback(
    (qr: ITableQuery<string>) => {
      const sortingParams: ITableColumnSort<string>[] = qr?.sortBy;
      const filteringParams: ITableColumnsFilter = qr?.filterBy;

      if (typeof qr !== 'object' || typeof filteringParams !== 'object' || typeof sortingParams !== 'object') {
        return;
      }

      setQuery(qr);

      const data: IGenericTableEntity[] = tableDataRef.current.slice();

      if (!Object.entries(filteringParams).length && !sortingParams.length) {
        tableEntriesSettingHandler(data);

        return;
      }

      handleQueryChange({
        tableData: data,
        filteringParams,
        sortingParams,
        tableEntriesSettingHandler,
        isTableProcessingSettingHandler,
      });
    },
    [tableDataRef, isTableProcessingSettingHandler, tableEntriesSettingHandler]
  );

  useEffect(() => {
    tableDataRef.current = tableData;

    changeQuery({ ...query });
    setTimeout(() => {
      isTableProcessingSettingHandler(false);
    }, TIME_TO_WAIT_FOR_QUERY_WITHOUT_LOADER);
    // this should be called only when tableData is modified
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  return [query, changeQuery];
}

function handleQueryChange({
  tableData,
  filteringParams,
  sortingParams,
  tableEntriesSettingHandler,
  isTableProcessingSettingHandler,
}: ITableQueryConfig) {
  // FIXME
  // eslint-disable-next-line prefer-const
  let timeoutId: NodeJS.Timeout;
  let isQueryWaitingTimeLeft = false;

  tableQueryWorker.onmessage = ({ data }: MessageEvent) => {
    tableEntriesSettingHandler(data);

    clearTimeout(timeoutId);

    if (isQueryWaitingTimeLeft) {
      isTableProcessingSettingHandler(false);
    }
  };

  tableQueryWorker.postMessage({
    tableData,
    filteringParams: getSerializedFilterParameters(Object.entries(filteringParams)),
    sortingParams,
  });

  timeoutId = setTimeout(() => {
    isQueryWaitingTimeLeft = true;

    isTableProcessingSettingHandler(true);
  }, TIME_TO_WAIT_FOR_QUERY_WITHOUT_LOADER);
}

function getSerializedFilterParameters(
  filterParameters: TableQueryFilterParameter[]
): TableQueryFilterParameterSerialized[] {
  return filterParameters.map(([queryField, filter]) => [
    queryField,
    {
      type: filter.getClassName(),
      filter: filter.serialize(),
    },
  ]);
}
