import { useCallback, useState } from 'react';

interface UseToggleElements<T> {
  isElementToggled: (element: T) => boolean;
  handleToggleElements: (element: T) => void;
  clearToggles: () => void;
  toggledElements: Set<T>;
}
/**
 * A generic function
 *
 * @typeParam T  The generic type parameter .
 * @param initialState  A generic option parameter  .
 * @returns A generic return initialState array or type parameter.
 */
export const useToggleElements = <T>(initialState?: T[]): UseToggleElements<T> => {
  const [toggle, setToggle] = useState<Set<T>>(new Set(initialState));

  const isElementToggled = useCallback((element: T) => toggle.has(element), [toggle]);

  const clearToggles = useCallback(() => {
    setToggle(new Set());
  }, []);

  const handleToggleElements = useCallback((element: T) => {
    setToggle((prev) => {
      const newSet = new Set([...prev]);
      if (newSet.has(element)) {
        newSet.delete(element);
      } else {
        newSet.add(element);
      }
      return newSet;
    });
  }, []);

  return { isElementToggled, handleToggleElements, clearToggles, toggledElements: toggle };
};
