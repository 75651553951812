import { useEffect, useMemo, useRef, useState } from 'react';
import * as _ from 'lodash';

import { useInterval } from './useInterval';
import { useWindowSize } from './sizes';

export interface IUseInfiniteScrollArgs {
  loading: boolean;

  isComplete: boolean;
  watchInterval?: number;
  threshold?: number;

  // Scrollable parent. should contain ref
  scrollContainer?: HTMLElement;

  onLoadMore: VoidFunction;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useInfiniteScroll<E extends HTMLElement = any>(args: IUseInfiniteScrollArgs) {
  const { isComplete, loading, scrollContainer, watchInterval = 150, onLoadMore, threshold = 150 } = args;
  const ref = useRef<E>();

  const [listen, setListen] = useState(true);
  const { height: windowHeight, width: windowWidth } = useWindowSize();

  useEffect(() => {
    if (!loading) {
      setListen(true);
    }
  }, [loading]);

  const contains = (container: HTMLElement, child: HTMLElement) => container !== child && container.contains(child);

  const isScrollContainer = useMemo(() => {
    if (!ref.current || !scrollContainer) {
      return false;
    }

    return _.isElement(scrollContainer) && contains(scrollContainer, ref.current);
  }, [scrollContainer]);

  const getBottomOffset = () => {
    const element = ref.current;
    if (!element || _.isUndefined(windowHeight)) {
      return;
    }

    const { bottom } = element.getBoundingClientRect();

    if (scrollContainer && isScrollContainer) {
      const { bottom: parentBottom } = scrollContainer.getBoundingClientRect();
      return bottom - parentBottom;
    }

    return bottom - windowHeight;
  };

  const isElementInView = (element: HTMLElement): boolean => {
    if (_.isUndefined(windowHeight) || _.isUndefined(windowWidth)) {
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (element) {
      const { left, right, top, bottom } = element.getBoundingClientRect();
      if (left > windowWidth) {
        return false;
      }
      if (right < 0) {
        return false;
      }
      if (top > windowHeight) {
        return false;
      }
      if (bottom < 0) {
        return false;
      }
    }

    return true;
  };

  const listenOffset = () => {
    if (!listen || loading || isComplete) {
      return;
    }

    if (!ref.current) {
      return;
    }

    if (scrollContainer && isScrollContainer && !isElementInView(scrollContainer)) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (ref.current && !isElementInView(ref.current)) {
      return;
    }

    const offset = getBottomOffset();
    if (_.isUndefined(offset)) {
      return;
    }

    if (offset < threshold) {
      setListen(false);
      onLoadMore();
    }
  };

  useInterval(
    () => {
      listenOffset();
    },
    !isComplete ? watchInterval : 0
  );

  return ref;
}
