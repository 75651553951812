import { QueryKey } from '@tanstack/react-query';
import { SvgIconComponent } from '@mui/icons-material';

import { Environment } from '@playq/octopus-common';
import { AttachmentSearchQuerySerialized } from '@playq/services-beetle';

import { IQuerySerialized } from '/common/models';

export type ClickEventType = {
  stopPropagation(): unknown;
  preventDefault: () => void;
  metaKey: boolean;
};
export interface Params {
  env: Environment;
  appName: string;
}
export interface BeetleLookupItems {
  tooltipTitle: string;
  color: Colors;
  title: string;
  type: SupportContentType;
  icon: SvgIconComponent;
  attachments?: BeetleLookupItems;
}
export enum SupportContentType {
  SUPPORT_REQUESTS = 'support_requests',
  ISSUES = 'issues',
  ATTACHMENTS = 'attachments',
  ISSUES_ATTACHMENTS = 'issues_attachments',
  SUPPORT_REQUESTS_ATTACHMENTS = 'support_requests_attachments',
  TASKS = 'tasks',
  EMPTY = '',
}

export type BeetleContentStateType = {
  env: Environment;
  id: string;
  app: string;
  appName?: string;
};
export type BeetleStateType = {
  env: Environment;
  currentPageQueryKey?: QueryKey;
  attachmentsQuery?: Partial<AttachmentSearchQuerySerialized>;
};

export enum Colors {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  INFO = 'info',
}

export interface IBeetleParams {
  app: string;
  id: string;
  appName: string;
  env: Environment;
}
export enum IResolvedColor {
  RESOLVED = 'resolved',
  UNRESOLVED = 'unresolved',
  RESOLVE = 'resolve',
  UNRESOLVE = 'unresolve',
}
export interface AttachmentSearchQueryProps extends IQuerySerialized {
  search: AttachmentSearchQuerySerialized;
  type: SupportContentType;
}
export interface HoverButtonProps {
  hovered: string | undefined;
}
