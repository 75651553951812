import { useCallback, useEffect, useState } from 'react';

import { QueryHelpers } from '/helpers';
import { createStorage, sessionStorageMethods } from '/storage';
import { IBaseSort, IQuery, PersistedQueryKeys, IQuerySerialized } from '/common/models';

export const storage = createStorage({ prefix: PersistedQueryKeys.StoragePrefix, storage: sessionStorageMethods });

export interface IPersistedQueryOptions<S extends string, SC extends IBaseSort<S>> {
  onBeforePersist?: (query: IQuery<S, SC>) => IQuery<S, SC>;
  onBeforeInit?: (query: IQuery<S, SC>) => IQuery<S, SC>;
}
export const usePersistedQuery = <S extends string, SC extends IBaseSort<S>>(
  key: string | undefined,
  SortClass?: new (bs: IBaseSort<string>) => SC,
  initialState?: IQuery<S, SC>,
  options?: IPersistedQueryOptions<S, SC>
) => {
  const beforeInit = (q: IQuery<S, SC>): IQuery<S, SC> => {
    if (options?.onBeforeInit) {
      return options.onBeforeInit(q);
    }

    return q;
  };

  const [query, setQuery] = useState<IQuery<S, SC>>(() => {
    if (initialState) {
      return initialState;
    }
    const initialQuery = QueryHelpers.getInitValue<S, SC>();
    if (!key) {
      return initialQuery;
    }
    const previousQuery = storage.get(key);

    if (previousQuery) {
      try {
        const result = QueryHelpers.deserialize<S, SC>(JSON.parse(previousQuery), SortClass);
        return beforeInit(result);
      } catch (e) {
        return beforeInit(initialQuery);
      }
    }
    return beforeInit(initialQuery);
  });

  const resetQuery = useCallback(() => {
    if (!key) {
      return;
    }
    const initialQuery = QueryHelpers.getInitValue<S, SC>();
    const initialQuerySerialized = QueryHelpers.serialize<S, SC>(initialQuery);

    storage.set(key, JSON.stringify(initialQuerySerialized));
  }, [key]);

  useEffect(() => {
    window.addEventListener('beforeunload', resetQuery);
    return () => {
      window.removeEventListener('beforeunload', resetQuery);
    };
  }, [resetQuery]);

  useEffect(() => {
    if (!key) {
      return;
    }
    let queryToPersist: IQuery<S, SC> | IQuerySerialized = query;
    if (options?.onBeforePersist) {
      queryToPersist = options.onBeforePersist(queryToPersist);
    }

    storage.set(key, JSON.stringify(QueryHelpers.serialize<S, SC>(queryToPersist)));
  }, [key, options, query]);

  return [query, setQuery] as const;
};
