import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Code, KeyboardArrowDown } from '@mui/icons-material';

import { ScriptRuntime } from '@playq/octopus2-scripts';

import { useScriptsMetadata } from '/common/scripts';
import { IUseScriptsMetadataResult } from '/common/scripts/types';
import { MenuCategorizedPopup } from '/shared/MenuCategorizedPopup';
import { IMenuCategoryData } from '/shared/MenuCategorizedContent/types';

import { ScriptsMenuToggler } from './styles';

export const InvokeAllScriptsButton: FC = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [scriptIDExpanded, setScriptIDExpanded] = useState<number | null>(null);
  const invokeButtonRef = useRef<HTMLButtonElement | null>(null);

  const { runtimes, areScriptsReady, invokeScriptsMethod }: IUseScriptsMetadataResult = useScriptsMetadata();

  const toggleScriptsMenu = () => {
    setMenuAnchorEl((menuAnchorElPrev: HTMLButtonElement | null) =>
      menuAnchorElPrev ? null : invokeButtonRef.current
    );
  };

  const handleMethodInvoke = useCallback(
    (scriptIDSerialized: string, methodName: string) => {
      invokeScriptsMethod(scriptIDSerialized, methodName);
      toggleScriptsMenu();
    },
    [invokeScriptsMethod]
  );

  const scriptsFiltered = useMemo<ScriptRuntime[]>(
    () => runtimes.filter(({ methods }: ScriptRuntime) => methods.length),
    [runtimes]
  );

  const scriptsMethodCategories = useMemo<IMenuCategoryData[]>(() => {
    const toggleScriptMethodsList = (id: number) => {
      setScriptIDExpanded((idExpanded: number | null) => (id === idExpanded ? null : id));
    };

    return scriptsFiltered.map(({ id, name, methods }: ScriptRuntime): IMenuCategoryData => {
      const scriptID: number = id.id;

      return {
        name,
        id: scriptID,
        isExpanded: scriptID === scriptIDExpanded,
        items:
          methods.map((methodName: string) => ({
            name: methodName,
            id: methodName,
            onClick: handleMethodInvoke.bind(null, id.serialize(), methodName),
          })) ?? [],
        onToggle: toggleScriptMethodsList.bind(null, scriptID),
      };
    });
  }, [scriptsFiltered, scriptIDExpanded, handleMethodInvoke]);

  useEffect(() => {
    if (!menuAnchorEl) {
      setScriptIDExpanded(null);
    }
  }, [menuAnchorEl]);

  return scriptsFiltered.length ? (
    <>
      <ScriptsMenuToggler
        ref={invokeButtonRef}
        title='Invoke service scripts'
        disabled={!areScriptsReady}
        endIcon={<KeyboardArrowDown />}
        onClick={toggleScriptsMenu}
      >
        <Code />
      </ScriptsMenuToggler>

      <MenuCategorizedPopup
        isOpen={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        categories={scriptsMethodCategories}
        label='Invoke scripts'
        onClose={toggleScriptsMenu}
      />
    </>
  ) : null;
};
