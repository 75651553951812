export const checkDots = (value: string) => {
  const removeDots = value.replace(/\./g, '');
  return +removeDots;
};

export const sortDate = (from: string, to: string): number => {
  const regex = /\d{1,2}\/\d{1,2}\/\d{2,4}/g;
  const isValidDate = from.match(regex) && to.match(regex) ? true : false;
  if (isValidDate) {
    return +new Date(from) - +new Date(to);
  }
  return 0;
};
export const sortNumber = (from: string, to: string): number => {
  return checkDots(from) - checkDots(to);
};
