import { TimeRange } from '@playq/octopus-common';

type TimeOptionsType = {
  hour: number;
  minute?: number;
  second?: number;
};

type TimeRangeOptionsType = {
  from_?: TimeOptionsType;
  till?: TimeOptionsType;
};

const setHoursAndMinutesFromTimeRangeOptions = (
  field: 'from_' | 'till',
  tr: TimeRange,
  options?: TimeRangeOptionsType
) => {
  if (!options?.[field]) {
    return;
  }
  const { hour, minute, second } = options[field] as TimeOptionsType;
  const time: [number] = [hour];
  if (minute !== undefined) {
    time.push(minute, second ?? 0);
  }
  tr[field].setHours(...time);
};

/**
 * Create timeRange based on 2 args.
 * In case argument less then 0 it will reassigned to 1
 * @param from {number} UnitType == day
 * @param till {number} UnitType == day
 * @param timeRangeOptions optional parameter with custom hours and minutes for from and till
 */
export const makeTimeRange = (from: number, till: number, options?: TimeRangeOptionsType): TimeRange => {
  const calcTime = (n: number) => 1000 * 60 * 60 * 24 * n;

  const f = from >= 0 ? from : 1;
  const t = till >= 0 ? till : 1;

  const tr = new TimeRange();
  tr.from_ = new Date();
  setHoursAndMinutesFromTimeRangeOptions('from_', tr, options);

  if (f !== 0) {
    tr.from_.setTime(tr.from_.getTime() - calcTime(f));
  }

  tr.till = new Date();
  setHoursAndMinutesFromTimeRangeOptions('till', tr, options);
  if (t !== 0) {
    tr.till.setTime(tr.till.getTime() + calcTime(t));
  }
  return tr;
};
