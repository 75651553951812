import { SetStateAction } from 'react';

import { KeyValueFilter, KeyValuePair } from '@playq/octopus-common';
import { MetaValueWithCounter, MetaKeyWithCounter } from '@playq/services-beetle';

import { IQuery } from '/common/models';

export const convertBeetleMetaToTagsWithCounters = <BeetleType extends { meta: { [key: string]: string } }>(
  acc: MetaKeyWithCounter[],
  { meta }: BeetleType
) => {
  const metaKeys = Object.keys(meta);
  if (!metaKeys.length) {
    return acc;
  }
  metaKeys.forEach((metaKey) => {
    const metaValue = meta[metaKey];
    const tagWithThisMetaKey = acc.find((tag) => tag.key === metaKey);
    if (tagWithThisMetaKey === undefined) {
      const newTag = new MetaKeyWithCounter();
      newTag.key = metaKey;
      newTag.count = 1;
      const newTagValue = new MetaValueWithCounter();
      newTagValue.value = metaValue;
      newTagValue.count = 1;
      newTag.values = [newTagValue];
      acc.push(newTag);
      return acc;
    }
    const tagCount = tagWithThisMetaKey.count;
    tagWithThisMetaKey.count = tagCount + 1;
    const tagWithThisMetaKeyValues = tagWithThisMetaKey.values;
    const tagValueWithThisMetaValue = tagWithThisMetaKeyValues.find(
      (valueWithCounter) => valueWithCounter.value === metaValue
    );
    if (tagValueWithThisMetaValue === undefined) {
      const newValue = new MetaValueWithCounter();
      newValue.value = metaValue;
      newValue.count = 1;
      tagWithThisMetaKey.values.push(newValue);
      return acc;
    }
    const valueCount = tagValueWithThisMetaValue.count;
    tagValueWithThisMetaValue.count = valueCount + 1;
  });
  return acc;
};

const emptyArray: [] = [];

export type SelectMetaTagPayload<BeetleSortType> = {
  key: string;
  value?: string;
  query: IQuery<string, BeetleSortType>;
  setQuery: (value: SetStateAction<IQuery<string, BeetleSortType>>) => void;
};

export const selectMetaTag = <BeetleSortType>({
  key,
  value,
  query,
  setQuery,
}: SelectMetaTagPayload<BeetleSortType>) => {
  const prevFilterByMeta = query.filterBy.Meta as KeyValueFilter | undefined;
  const prevPairs = prevFilterByMeta?.pairs || emptyArray;
  const isAlreadySelected = prevPairs.some(
    ({ key: pairKey, value: pairValue }) => pairKey === key && pairValue === value
  );
  if (isAlreadySelected) {
    return;
  }
  const newQuery = { ...query };
  const newFilter = new KeyValueFilter(prevFilterByMeta?.serialize());
  const newPair = new KeyValuePair({ key, value });
  newFilter.pairs = prevPairs.concat(newPair);
  newQuery.filterBy = { ...query.filterBy, Meta: newFilter };
  setQuery(newQuery);
};

export const existMeta = (meta: { [key: string]: string }) => {
  return Object.keys(meta).length > 0;
};

export const handleEntriesMeta = (metaEntries?: { [key: string]: string }) =>
  !metaEntries ? [] : Object.entries(metaEntries);
