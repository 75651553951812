import { useEffect, useRef } from 'react';
/**
 * Accepts a value and returns an up to date reference to it.
 * @template T - the type of a value.
 * @param valueToTrack a value to create a reference for.
 * @param initialValue (optional) an initial value of the returned value ref (defaults to valueToTrack).
 * @returns an up to date reference to the value.
 */
export function useTrackableValueRef<T = undefined>(...[valueToTrack, initialValue]: [T] | [T, T]) {
  const valueRef = useRef((arguments.length > 1 ? initialValue : valueToTrack) as T);

  useEffect(() => {
    valueRef.current = valueToTrack;
  }, [valueToTrack]);

  return valueRef;
}
