import { useEffect, useState } from 'react';
import { throttle } from 'lodash';

const events = ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel'];
const ms = 10 * 1000;

export const useIdle = (): boolean => {
  const [state, setState] = useState(false);

  useEffect(() => {
    let mounted = true;
    let timeout: number;
    let localState = false;

    const set = (newState: boolean) => {
      if (mounted) {
        localState = newState;
        setState(newState);
      }
    };

    const onEvent = throttle(() => {
      if (localState) {
        set(false);
      }

      clearTimeout(timeout);
      timeout = window.setTimeout(() => set(true), ms);
    }, 50);
    const onVisibility = () => {
      if (!document.hidden) {
        onEvent();
      }
    };

    for (const e of events) {
      window.addEventListener(e, onEvent);
    }
    document.addEventListener('visibilitychange', onVisibility);

    timeout = window.setTimeout(() => set(true), ms);

    return () => {
      mounted = false;

      for (const e of events) {
        window.removeEventListener(e, onEvent);
      }
      document.removeEventListener('visibilitychange', onVisibility);
    };
  }, []);

  return state;
};
