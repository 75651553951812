import { Accepted, Declined, BeingProcessed, Resolved, RequestStatus, Sent } from '@playq/services-beetle';

import { UpdateRequestType } from '../components';

export const createRequestStatusByTitle = (title: UpdateRequestType): RequestStatus | null => {
  switch (title) {
    case 'Accepted':
      return new Accepted();
    case 'Declined':
      return new Declined();
    case 'BeingProcessed':
      return new BeingProcessed();
    case 'Resolved':
      return new Resolved();
    case 'Sent':
      return new Sent();
    default:
      return null;
  }
};
