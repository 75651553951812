import { string } from 'yup';
import { HelpOutline, AttachFileOutlined, ScatterPlot } from '@mui/icons-material';

import { Environment } from '@playq/octopus-common';

import { UpdateRequestType } from './components/updateLabels/types';
import { BeetleLookupItems, Colors, SupportContentType } from './types';

export const initialEnv = Environment.Live;

export const requiredErrorMessage = 'This field is required';
export const guidErrorMessage = 'Field expects GUID format';

export const updateSupportRequestPlaceholder = 'To Do';
export const updateRequestTitles = Object.keys(UpdateRequestType);

export const updateRequestTitlesObject: { [key: string]: string } = {
  Accepted: 'Accepted',
  Declined: 'Declined',
  BeingProcessed: 'Being Processed',
  Resolved: 'Resolved',
  Sent: 'Sent',
};

export const unresolveIssue = 'Unresolve';

const notesSchema = string().trim().required('Note text is required!');

export const notesDialogOptions = {
  placeholder: 'Type the note you want to add',
  schema: notesSchema,
  title: 'Note editor',
};

export const statusFilterData = ['RESOLVED', 'NOT RESOLVED'];

export const fiveteenMinutes = 15 * 60 * 1000;
const attachments = {
  tooltipTitle: 'Go to Attachments',
  color: Colors.INFO,
  title: 'Attachments',
  type: SupportContentType.ATTACHMENTS,
  icon: AttachFileOutlined,
};
export const beetleLookupItems: BeetleLookupItems[] = [
  {
    tooltipTitle: 'Go to Support Requests',
    color: Colors.PRIMARY,
    title: 'Support Requests',
    type: SupportContentType.SUPPORT_REQUESTS,
    icon: HelpOutline,
    attachments: {
      ...attachments,
      tooltipTitle: 'Go to Support Requests Attachments',
      type: SupportContentType.SUPPORT_REQUESTS_ATTACHMENTS,
    },
  },
  {
    tooltipTitle: 'Go to Issues',
    color: Colors.SECONDARY,
    title: 'Issues',
    type: SupportContentType.ISSUES,
    icon: HelpOutline,
    attachments: {
      ...attachments,
      tooltipTitle: 'Go to Issues Attachments',
      type: SupportContentType.ISSUES_ATTACHMENTS,
    },
  },
  {
    ...attachments,
  },
  {
    tooltipTitle: 'Go to Clusterization',
    color: Colors.PRIMARY,
    title: 'Clusterization',
    type: SupportContentType.TASKS,
    icon: ScatterPlot,
  },
];
