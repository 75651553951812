import { useEffect, useMemo } from 'react';
import { Observable, BehaviorSubject } from 'rxjs';

/**
 * Accepts an actual value and returns an observable emitting ones on an actual value change.
 * @template T the type of the value.
 */
export function useValueAsObservable<T>(value: T) {
  const value$$ = useMemo<BehaviorSubject<T>>(
    () => new BehaviorSubject(value),
    // missing dependency: "value"
    // it is only presented here for BehaviorSubject initialization and should not trigger further value$$ changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const value$ = useMemo<Observable<T>>(() => value$$.asObservable(), [value$$]);

  useEffect(() => {
    value$$.next(value);
  }, [value, value$$]);

  useEffect(() => {
    return () => value$$.complete();
  }, [value$$]);

  return value$;
}
