import { useEffect, useState } from 'react';
import _ from 'lodash';

import { createStorage, sessionStorageMethods } from '/storage';
import { QueryHelpers } from '/helpers/query';
import { PersistedQueryKeys, IParsedQuery } from '/common/models';
import { ITableQuery } from '/shared/Table';
import { getPredefinedQuery } from '/shared/Table/helpers';

const storage = createStorage({ prefix: PersistedQueryKeys.StoragePrefix, storage: sessionStorageMethods });

/**
 * a generic interface that sets the types of useGenericTablePersistedQuery arguments
 * @param S an enum with string values or any string that can be used to access the keys of GenericTableEntity
 */
type GenericTablePersistedQueryPropsType<S extends string> = {
  key: string;
  initialState?: ITableQuery<S>;
};

/**
 * a function that gets table query as an argument and saves it to the local storage
 * @param query table query that should be saved to local storage
 * @param key an unique key of local storage where the table query should be saved
 */
const saveQueryToStorage = <S extends string>(query: ITableQuery<S>, key: string) => {
  const { filterBy } = query;

  const dataSerialized = {
    ...query,
    filterBy: QueryHelpers.serializeFilterBy(filterBy),
  };
  storage.set(key, JSON.stringify(dataSerialized));
};

/**
 * a function that gets table query from local storage if it exists
 * @param key an unique key that can be used to extract data from local storage
 * @returns table query from local storage or undefined if there is no table query in local storage
 */
const getQueryFromStorage = <S extends string>(key: string): ITableQuery<S> | undefined => {
  const dataAsString = storage.get(key);
  if (_.isNil(dataAsString)) {
    return;
  }
  const dataSerialized = JSON.parse(dataAsString) as IParsedQuery<S>;
  const data: ITableQuery<S> = {
    ...dataSerialized,
    filterBy: QueryHelpers.deserializeFilterBy(dataSerialized.filterBy),
  };

  return data;
};

/**
 * A hook that saves queries for GenericTables of entities without specified SortClass
 * @param key an unique key that can be used to extract data from local storage
 * @param initialState optional parameter, table query that will be returned if there is no another table query in local storage
 */
export const useGenericTablePersistedQuery = <S extends string>({
  key,
  initialState,
}: GenericTablePersistedQueryPropsType<S>) => {
  const [query, setQuery] = useState<ITableQuery<S>>(() => {
    const initialQuery: ITableQuery<S> = getPredefinedQuery(initialState);
    const previousQuery: ITableQuery<S> | undefined = getQueryFromStorage<S>(key);
    return previousQuery ?? initialQuery;
  });

  const { filterBy, sortBy, page, rowsPerPage } = query;

  useEffect(() => {
    saveQueryToStorage(query, key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, filterBy, sortBy, page, rowsPerPage]);

  return [query, setQuery] as const;
};
