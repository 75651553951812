import { useCallback, useEffect, useState } from 'react';

import { IBoundingRect } from '/hooks';

export const useWindowSize = (): IBoundingRect => {
  const getBounds = useCallback((): IBoundingRect => ({ width: window.innerWidth, height: window.innerHeight }), []);

  const [bounds, setBounds] = useState(() => getBounds());

  const resize = useCallback(() => setBounds(getBounds()), [getBounds]);

  useEffect(() => {
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [resize]);

  return bounds;
};
