import { AttachmentType } from '@playq/services-beetle';
import { OffsetLimit } from '@playq/services-shared';

export const downloadAttachment = (attachmentURL: string, name: string, type: AttachmentType) => () => {
  const link = document.createElement('a');
  link.download = name;
  link.type = type;
  link.href = attachmentURL;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const goToAttacmentURL = (url: string) => {
  window.open(url, '_blank')?.focus();
};

export const countAttachment = (
  nextOffsetLimit: OffsetLimit | undefined,
  currentLenght: number,
  currentIterator: OffsetLimit,
  prefetch: number
): number => {
  const nextOffsetLimitSerialised = nextOffsetLimit?.serialize();
  return nextOffsetLimitSerialised && prefetch && currentLenght === currentIterator.limit
    ? nextOffsetLimitSerialised.offset + nextOffsetLimitSerialised.limit
    : nextOffsetLimitSerialised?.offset ?? 0;
};
