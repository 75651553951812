import { useEffect, useLayoutEffect, useState } from 'react';
import { debounceTime, distinctUntilChanged } from 'rxjs';

import { getElementResizeObservable } from '/helpers';

import { RESIZE_LISTENER_DEBOUNCE_TIME_MS } from './constants';
import { IBoundingRect, IUseSizeListenerParams } from './types';

export function useSize({
  element,
  shouldListenToResize = false,
  resizeListenerOptions,
}: IUseSizeListenerParams): IBoundingRect {
  const { listenerDebounceTime = RESIZE_LISTENER_DEBOUNCE_TIME_MS, resizeHandlingCallback } =
    resizeListenerOptions ?? {};

  const [bounds, setBounds] = useState<IBoundingRect>({
    width: 0,
    height: 0,
  });

  useLayoutEffect(() => {
    if (!element) {
      return;
    }

    const { width, height } = element.getBoundingClientRect();

    setBounds({ width, height });

    if (shouldListenToResize) {
      const subscription = getElementResizeObservable(element)
        .pipe(debounceTime(listenerDebounceTime), distinctUntilChanged())
        .subscribe((boundingRect: IBoundingRect) => {
          setBounds(boundingRect);
        });

      return () => subscription.unsubscribe();
    }
  }, [element, shouldListenToResize, listenerDebounceTime]);

  useEffect(() => {
    resizeHandlingCallback?.();
  }, [bounds, resizeHandlingCallback]);

  return bounds;
}
