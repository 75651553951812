import { Environment } from '@playq/octopus-common';

interface IAws {
  namespace: string;
  s3: {
    buckets: { bucketName: string; overrideName: string }[];
  };
}

const protocol = 'https://';
const host = 'octopusconfigs.playq.net';

function makeBucketName(bucketname: string, namespace: string): string {
  const envBucketname = bucketname !== 'live' ? `${bucketname}-` : '';
  const envNamespace = namespace !== 'prd' ? `${namespace}-` : '';
  return `${envBucketname}${envNamespace}`;
}

export function getBucketName(bucketname: string, aws: IAws): string {
  const { namespace, s3 } = aws;
  const bucket = s3.buckets.find((e) => e.bucketName === bucketname);
  return !bucket?.overrideName ? makeBucketName(bucketname, namespace) : `${bucket.overrideName}-`;
}

export function makeCFURI(bucket: string): string {
  return protocol + bucket + host;
}

export const buckets = {
  [Environment.Sandbox]: makeCFURI(getBucketName(Environment.Sandbox.toLowerCase(), __ENV_CFG__.aws)),
  [Environment.Live]: makeCFURI(getBucketName(Environment.Live.toLowerCase(), __ENV_CFG__.aws)),
};
