import './styles.scss';

const test = document.createElement('div');
test.innerHTML = '&nbsp;';
test.className = 'ad_wrapper';
document.body.appendChild(test);
window.setTimeout(() => {
  if (test.offsetHeight === 0) {
    document.body.classList.add('adblock');
  }
  test.remove();
}, 1000);
