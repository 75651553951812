import { useCallback } from 'react';
import { QueryClient, QueryKey, Updater } from '@tanstack/query-core';

import { OffsetLimit } from '@playq/services-shared';

interface DeleteCacheBatchProps<R, ID, S> {
  mutate: (updater: Updater<R | undefined, R | undefined>) => void;
  total: number;
  nextKeys?: QueryKey;
  queryClient: QueryClient;
  getSerialize: (response: R) => S[];
  responseClass: (newArray: S[], iterator: OffsetLimit, currentTotal: number) => R;
  compareIds: (item: S, id: ID) => boolean;
}
export const useDeleteCacheBatch = <R extends object, ID, S>(args: DeleteCacheBatchProps<R, ID, S>) => {
  const { mutate, total, nextKeys, getSerialize, responseClass, queryClient, compareIds } = args;
  return useCallback(
    (ids: ID[], updatedIterator: OffsetLimit) => {
      mutate((prev) => {
        if (!prev) {
          return;
        }
        const newResponse = prev;
        const serializeArray = getSerialize(newResponse);
        const selectedIndex = ids.map((id) => {
          return serializeArray.findIndex((item) => compareIds(item, id));
        });
        if (!selectedIndex.includes(-1)) {
          const newArray = serializeArray.filter((_, i) => {
            return !selectedIndex.includes(i);
          });

          if (total - selectedIndex.length > updatedIterator.limit + updatedIterator.offset && nextKeys) {
            const nextPageData = queryClient.getQueryData(nextKeys) as R;
            const nextPageIssues = nextPageData;
            return responseClass(
              newArray.concat(getSerialize(nextPageIssues).slice(0, selectedIndex.length)),
              updatedIterator,
              total - selectedIndex.length
            );
          }
          return responseClass(newArray, updatedIterator, total - selectedIndex.length);
        }
      });
    },
    [compareIds, getSerialize, mutate, nextKeys, queryClient, responseClass, total]
  );
};
