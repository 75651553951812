import { ReactNode } from 'react';

import { Environment } from '@playq/octopus-common';
import { AppSupportRequestBrief, RequestStatus } from '@playq/services-beetle';

export enum UpdateRequestType {
  Accepted = 'Accepted',
  Declined = 'Declined',
  BeingProcessed = 'BeingProcessed',
  Resolved = 'Resolved',
  Sent = 'Sent',
}

export interface IUpdateSupportRequestActionProps {
  env: Environment;
  request: AppSupportRequestBrief;
  readonly: boolean;
  onSetStatus: (status: RequestStatus) => void;
  renderLabel?: (request: AppSupportRequestBrief, readonly?: boolean, isFetching?: boolean) => ReactNode;
  onStatusProcessing: (value: boolean) => void;
}
